import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { desktopDown, phabletAndBelow } from '@/app/common/style/mixins';
import { COLOR } from '@/app/common/style/variables';
import { body4 } from '@/app/common/style/typography';

export const Wrapper = styled.div`
  margin-bottom: 0;
  padding-top: 16px;

  &.section-cards-layout-tiled {
    padding-top: 102px;
    padding-bottom: 140px;
  }

  ${desktopDown(css`
    &.section-cards-layout-tiled {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  `)}

  ${phabletAndBelow(css`
    &.section-cards-layout-tiled {
      padding-top: 60px;
      padding-bottom: 40px;
    }
  `)}
`;

export const Title = styled.h3``;

export const CardsWrapper = styled.div`
  display: flex;
  margin: 0 -30px;

  .section-cards-layout-row & {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section-cards-layout-tiled & {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  ${desktopDown(css`
    margin: 0 -15px;
  `)}

  ${phabletAndBelow(css`
    .section-cards-layout-row & {
      flex-direction: column;
      /* flex-wrap: wrap; */
    }

    .section-cards-layout-tiled & {
      margin: 0;
      flex-direction: column;
      /* flex-wrap: wrap;
      justify-content: center; */
    }
  `)}
`;

export const CardWrapper = styled.div`
  .section-cards-layout-row & {
    padding: 0 30px;
    margin-bottom: 60px;
  }

  .section-cards-layout-row.section-cards-columns-2 & {
    width: 50%;
  }

  .section-cards-layout-row.section-cards-columns-3 & {
    width: 33.3333%;
  }

  .section-cards-layout-tiled & {
    width: 330px;

    .card-image {
      max-width: 100px;
      min-width: 100px;
    }

    .card-content {
      color: ${COLOR.woodSmoke3};
      max-width: 290px;

      h4 {
        ${body4}
        margin-bottom: 3.5px;
      }
    }

    .card-has-content .card-image {
      margin-right: 32px;
    }
  }

  ${desktopDown(css`
    .section-cards-layout-row & {
      padding: 0 15px;
      margin-bottom: 60px;
    }

    .section-cards-layout-tiled & {
      width: 300px;

      .card-layout-image-on-left.card-has-content .card-image {
        margin-right: 16px;
      }
    }
  `)}

  ${phabletAndBelow(css`
    .section-cards-layout-row.section-cards-columns-2 & {
      width: 100%;
    }

    .section-cards-layout-row.section-cards-columns-3 & {
      width: 100%;
    }

    .section-cards-layout-tiled & {
      width: 100%;
      margin-bottom: 20px;

      .card-layout-image-on-left.card-has-content .card-image {
        margin-right: 30px;
      }
    }
  `)}
`;
