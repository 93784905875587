import React, { FC } from 'react';
import kebabCase from 'lodash/kebabCase';
import clsx from 'clsx';

import { CardWrapper, CardsWrapper, Title, Wrapper } from './styles';
import { ContentfulSection } from '../types.d';
import Card, { ContentfulCard } from '@/components/card/Card';

export interface CardsSection extends ContentfulSection {
  cards?: ContentfulCard[];
  columns?: number;
  layout?: 'Row' | 'Column' | 'Tiled';
  title?: string;
}

/**
 * Cards section
 */
const Cards: FC<CardsSection> = ({
  cards = [],
  columns = 3,
  layout = `Row`,
  title,
}) => (
  <Wrapper
    className={clsx(
      kebabCase(`Section Cards Layout ${layout}`),
      kebabCase(`Section Cards Columns ${columns}`),
    )}
  >
    {!!title && <Title>{title}</Title>}
    {!!cards.length && (
      <CardsWrapper>
        {cards.map((card, index) => (
          <CardWrapper key={`${card.id}-${index}`}>
            <Card {...card} />
          </CardWrapper>
        ))}
      </CardsWrapper>
    )}
  </Wrapper>
);

export default Cards;
